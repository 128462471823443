export default {
  header: 'Pages',
  icon: 'FileIcon',
  children: [
    {
      title: 'Inicio',
      route: 'central',
      icon: 'HomeIcon',
    },
    {
      title: 'Second Page',
      route: 'second-page',
      icon: 'FileIcon',
    },
  ],
}
